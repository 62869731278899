<template>
    <div v-if="!noDate" class="list">
            <div class="l_wrap"  v-for="board in boardData" :key="`board${board.wr_id}`" @click.self="checkScrect(board)">

                <div class="content" @click.self="checkScrect(board)">
                    <div class="text" @click.self="checkScrect(board)">
                        <div class="name"  @click="checkScrect(board)">{{board.Writer.mb_name}}</div>
                        <div class="date"  @click="checkScrect(board)">{{returnDate(board.created_at)}}</div>
                        <div class="title" @click="checkScrect(board)">{{board.wr_subject}}</div>
                        <div class="info" @click.self="checkScrect(board)">
                            <!--                        <div class="like">555555</div>-->
                            <board-like-component :boardType="'mentorLike'" :boardData="board"></board-like-component>
                            <div class="comment" @click="checkScrect(board)">{{returnCount(board.CommentCount, 999)}}</div>
                        </div>
                    </div>
                    <div class="thumbnail" @click="checkScrect(board)" v-if="hasImage(board)">
                        <img :src="returnImageUrl(board)"> <!--파일은 이곳에-->
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";
    import {mapState, mapGetters} from "vuex";
    import VuxAlert from "@/mixins/vux-alert";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BoardListMentorCommuLayout",
        components: {
            BoardLikeComponent
        },
        mixins: [VuxAlert, imageOption],
        props: {
            boardData: {
                type: Array,
                default: () => {
                    return []
                },
            },
            total: {
                type: Number,
                default: 0,
            },
            categorys: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
                noDate: false,
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        mounted() {
        },
        methods: {
            checkScrect(board) {
                if ((this.UserInfo.mb_type === 1 || this.UserInfo.mb_type === 0) && this.hasScrect(board)) {
                    this.createConfirm({
                        title: '',
                        content: `아이에겐 심각하고 진지한, 심지어는 부끄럽고 창피한 글 일수도 있습니다.<br/>진지하게 답변해주시기 바라며 해당 내용을 어떠한 곳에도 공유/게시하지 않을 것을 약속해주세요.<br>위반시 불이익을 당할 수 있습니다.`,
                        confirmText: '확인',
                        cancelText: '취소',
                        confirm: () => {
                            this.goDetail(board.wr_id);
                        }
                    })
                    return;
                }
                this.goDetail(board.wr_id);
            },
            goDetail(idx) {
                let detailLink = this.$route.meta.parentLink ? this.$route.meta.parentLink : '/mentor';
                if (detailLink === '/mypage') {
                    this.$router.push(`${detailLink}/${this.UserInfo.mb_no}/mentor/${idx}`)
                } else {
                    this.$router.push(`${detailLink}/${idx}`);
                }
            },
            returnDate(date) {
                if (!date) {
                    return '';
                }
                this.$moment(date).format('YYYY.MM.DD HH:mm')
            },
            returnCount(count, maxCount) {
                if (maxCount < count) {
                    return maxCount + '+';
                }
                return count
            },
            returnImageUrl(board) {
                return  this.hasImage(board) ? `${board.Files[0].org_url}?${this.setImageOptions(198, 194, 'png')}` : '';
            },
            hasImage(board) {
                return board.hasOwnProperty('Files') && board.Files.length > 0 && board.Files[0].a_idx != null;
            },
            hasScrect(board) {
                return board.secret != null;
            },
        },
    }
</script>

<style scoped>

</style>